body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main-container {
    flex-grow: 1;
    padding: 24px;
    .top-brick {
        width: 100%;
        min-height: 69px;
    }
    .base__main-title {
        margin: 0;
        font-size: 1.75em;
        margin-bottom: 24px;
        font-weight: 800;
    }
    .tools {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 1rem auto;
    }
    .base__btn-action {
        text-transform: uppercase;
        font-weight: 800;
        margin-right: .5rem;
    }
}

.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.rounded-pill {
    border-radius: 50rem!important;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(13, 110, 253, var(--bs-bg-opacity))!important;
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(108, 117, 125, var(--bs-bg-opacity))!important;
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(25, 135, 84, var(--bs-bg-opacity))!important;
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(220, 53, 69, var(--bs-bg-opacity))!important;
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--bs-bg-opacity))!important;
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(33, 37, 41, var(--bs-text-opacity))!important;
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(13, 202, 240, var(--bs-bg-opacity))!important;
}